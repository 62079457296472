<template>
    <div v-if="isVisible" class="feedback-popup-overlay">
        <div class="feedback-popup card">
            <button class="close-btn btn-close" aria-label="Close" @click="closePopup"></button>
            <div class="popup-content">
                <h5 class="popup-title">Trouvez-vous LexiConcordia utile?</h5>
                <div class="emoji-buttons d-flex mt-3 mb-4">
                    <button v-for="(emoji, index) in emojis"
                            :key="index"
                            class="emoji-btn btn-outline-secondary rounded mx-1"
                            :class="{ active: selectedEmoji === index }"
                            @click="selectEmoji(index)">
                        {{ emoji.emoji }}
                    </button>
                </div>
                <h5 class="popup-title">Avez-vous des suggestions ou des commentaires?</h5>
                <textarea
                  class="form-control my-3"
                  rows="3"
                  placeholder="Écrivez vos commentaires ici"
                  v-model="commentText"
                ></textarea>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-secondary lexi-btn lexi-btn-secondary me-2 mb-0" @click="closePopup">Annuler</button>
                    <button
                      class="btn btn-primary lexi-btn lexi-btn-primary mb-0"
                      @click="sendFeedback"
                    >
                      Envoyer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getDatabase, ref, push } from "firebase/database";
import app from "../firebaseConfig"; // Import the Firebase configuration file

export default {
  name: "FeedbackPopup",
  data() {
    return {
      isVisible: false,
      selectedEmoji: null,
      emojis: [
        { emoji: "☹️", alt: "Très insatisfait" },
        { emoji: "😐", alt: "Insatisfait" },
        { emoji: "😊", alt: "Satisfait" },
        { emoji: "😃", alt: "Très satisfait" },
      ],
      commentText: "", // Track the comment input
    };
  },
  methods: {
    showPopup() {
      this.isVisible = true;
      console.log("Popup displayed...");
    },
    closePopup() {
      this.isVisible = false;
    },
    selectEmoji(index) {
      this.selectedEmoji = index; // Update the selected emoji
    },
    async sendFeedback() {
      if (this.selectedEmoji === null) {
        alert("Veuillez sélectionner un emoji."); // Alert if no emoji is selected
        return;
      }

      const feedbackData = {
        emoji: this.emojis[this.selectedEmoji].alt, // Selected emoji description
        comment: this.commentText, // User's comment
        timestamp: new Date().toISOString(), // Timestamp
      };

      try {
        const db = getDatabase(app); // Initialize the database
        const feedbackRef = ref(db, "feedback"); // Reference the "feedback" node
        await push(feedbackRef, feedbackData); // Push the feedback data
        alert("Merci pour votre retour !");
        this.closePopup(); // Close the popup
      } catch (error) {
        console.error("Error saving feedback:", error);
        alert("Erreur de connexion. Réessayez plus tard.");
      }
    },
  },

  mounted() {
    // Show the popup after 1 minute
    setTimeout(() => {
      this.showPopup();
    }, 120001);
  },
};
</script>


<style scoped>
    .lexi-btn-primary {
        font-size: 16px !important;
        font-weight: 700;
        border-radius: 2px !important;
        letter-spacing: -.25px;
        padding: 10px 20px !important;
        &:hover {
            filter: brightness(1.1) !important;
        }
    }

    .lexi-btn-secondary {
        font-size: 16px !important;
        font-weight: 700;
        border-radius: 2px !important;
        letter-spacing: -.25px;
        background-color: #F0F0F0 !important;
        color: black !important;
        padding: 10px 20px !important;
        &:hover {
            background-color: #E0E0E0 !important;
        }
    }

    .feedback-popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1050;
    }

    .feedback-popup {
        max-width: 85vw;
        width: 600px;
        background-color: #fff;
        border-radius: 4px;
        padding: 1.25rem;
        position: relative;
    }

    .close-btn {
        position: absolute;
        top: 1.25rem;
        right: 1.25rem;
    }

    .popup-title {
        font-family: "Gill Sans MT Pro Bold Condensed";
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .emoji-buttons .emoji-btn {
        background-color: transparent;
        border: 1px solid #ccc;
        border-radius: 50%;
        padding: 10px;
        transition: background-color 0.2s, border-color 0.2s;
        font-size: 24px !important;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        &:hover {
            background-color: #f0f0f0;
        }
    }

    .emoji-buttons .emoji-btn img {
        width: 24px;
        height: 24px;
    }

    .emoji-buttons .emoji-btn.active {
        background-color: #ddd; /* Active state background */
        border-color: #666; /* Active state border */
    }
</style>
