<template>
    <div class="container-fluid">
        <AppHeader />

        <main class="container">
            <div v-if="isLoading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Chargement...</span>
                </div>
            </div>

            <div v-else>
                <!-- Main Content Section -->
                <div class="lx-details row justify-content-center mb-5">
                    <div class="lx-details__content width940 col-12 col-lg-10">
                        <!-- French Term Section -->
                        <div class="lx-details__french-term mt-3 mb-4">
                            <div class="lx-details__french-term-wrapper d-flex flex-column gap-1">
                                <div class="mb-2">
                                    <p class="lx-language-label mb-0" style="font-size: 1rem;">Français</p>
                                    <h1 class="lx-h1 mb-0">
                                        {{ resultDetails.VEDETTE_FRANÇAISE || 'N/A' }}
                                        <span class="lx-h1-book mb-0">{{ resultDetails.INDICATIONS_GRAMMATICALES || '' }}</span>
                                    </h1>
                                </div>
                                <div class="d-flex gap-1">
                                    <button class="lx-details__button shadow-sm" @click="speak(resultDetails.VEDETTE_FRANÇAISE)">écouter
                                            <img src="@/assets/speakerIcon.svg" alt="Icône de haut-parleur" width="16" height="16" />
                                    </button>
                                    <button class="lx-details__button shadow-sm" @click="copyText(resultDetails.VEDETTE_FRANÇAISE)">copier
                                            <img src="@/assets/copyIcon.svg" alt="Icône de copie du terme français" width="12" height="12" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- English Term Section -->
                        <div class="lx-details__english-term mt-3 mb-5">
                            <div class="lx-details__english-term-wrapper d-flex flex-column gap-1">
                                <div class="mb-2">
                                    <p class="lx-language-label mb-0" style="font-size: 1rem;">English</p>
                                    <h1 class="lx-h1-book mb-0">{{ resultDetails.VEDETTE_ANGLAISE || 'N/A' }}</h1>
                                </div>
                                <div class="d-flex gap-1">
                                    <button class="lx-details__button shadow-sm" @click="copyText(resultDetails.VEDETTE_ANGLAISE)">copier
                                            <img src="@/assets/copyIcon.svg" alt="Icône de copie du terme anglais" width="12" height="12" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Definition Section -->
                        <div class="lx-details__definition mb-4">
                            <h2 class="lx-h2">Définition</h2>
                            <p class="text-xlarge">{{ resultDetails.DEFINITION || 'N/A' }}</p>
                        </div>

                        <!-- Domain Section -->
                        <div class="lx-details__domain-section row pt-4 mb-4 border-top border-bottom">
                            <div class="lx-details__domain-column col-md-6 mb-4">
                                <h3 class="lx-h3">Domaine</h3>
                                <div class="card shadow-sm hover-overlay mb-3"
                                     @click="navigateToDomain(resultDetails.DOMAINE)"
                                     role="link"
                                     tabindex="0"
                                     @keydown.enter="navigateToDomain(resultDetails.DOMAINE)"
                                     @keydown.space.prevent="navigateToDomain(resultDetails.DOMAINE)">
                                    <div class="card-body d-flex align-items-start">
                                        <div class="d-flex align-items-center justify-content-center me-4">
                                            <img :src="getDomainImage(resultDetails.DOMAINE)" 
                                                 :alt="`${resultDetails.DOMAINE} Icon`" 
                                                 class="img-fluid" 
                                                 style="max-width: 48px;" />
                                        </div>
                                        <div class="flex-grow-1">
                                            <h4 class="lx-h3 mb-1" lang="fr">{{ resultDetails.DOMAINE || 'N/A' }}</h4>
                                            <p class="lx-subtitle text-muted mb-2" lang="fr">{{ getDomainSubtitle(resultDetails.DOMAINE) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lx-details__subdomain-column col-md-6 mb-4">
                                <h3 class="lx-h3">Sous-domaine</h3>
                                <p class="text-xlarge">{{ resultDetails.SOUS_DOMAINE || 'N/A' }}</p>
                            </div>
                        </div>

                        <!-- Context Section -->
                        <div class="lx-details__context mb-4">
                            <h3 class="lx-h3">Contexte français</h3>
                            <div v-html="highlightedContext" class="lx-details__context-content mb-3 text-xlarge"></div>
                            <h4 class="lx-h3">Source contexte français</h4>
                            <div v-html="linkedSource" class="lx-details__context-source text-xlarge"></div>
                        </div>

                        <!-- Note Française Section -->
                        <div v-if="resultDetails.NOTE_FRANÇAISE && resultDetails.NOTE_FRANÇAISE !== 'None'" class="lx-details__note-francaise mb-4">
                            <h3 class="lx-h3">Note française</h3>
                            <p class="text-xlarge">{{ resultDetails.NOTE_FRANÇAISE }}</p>
                        </div>

                        <!-- Synonyme Française Section -->
                        <div v-if="resultDetails.SYNONYME_FRANÇAISE && resultDetails.SYNONYME_FRANÇAISE !== 'None'" class="lx-details__synonyme-francaise mb-4">
                            <h3 class="lx-h3">Synonymes</h3>
                            <p class="text-xlarge">{{ resultDetails.SYNONYME_FRANÇAISE }}</p>
                        </div>

                        <!-- Author Section -->
                        <div class="lx-details__author my-5 border-top pt-3">
                            <p class="text-xlarge">Rédigé par: {{ resultDetails.FICHE_CRÉÉE_PAR || 'N/A' }}</p>
                        </div>

                        <!-- Evaluation Section -->

                    </div>
                </div>
            </div>
        </main>
        <div class="row justify-content-center lx-bg-light py-5">
            <div class="lx-evaluation p-5 shadow-lg width940 col-12 col-lg-10">
                <div class="lx-evaluation__body">
                    <h3 class="lx-h3">Évaluation de la page</h3>
                    <p class="lx-evaluation__text text-xlarge">
                        Avez-vous un commentaire à nous transmettre ou un problème à signaler?
                    </p>
                    <p class="lx-evaluation__text text-xlarge">
                        Évitez d'inscrire des renseignements personnels. Prenez note que vous ne recevrez aucune réponse.
                    </p>
                    <div class="lx-evaluation__options mb-3">
                        <div class="lx-evaluation__option">
                            <input class="lx-evaluation__input text-xlarge me-3"
                                   type="radio"
                                   name="evaluation"
                                   id="evalOui"
                                   value="oui"
                                   v-model="evaluationResponse">
                            <label class="lx-evaluation__label" for="evalOui">Oui</label>
                        </div>
                        <div class="lx-evaluation__option">
                            <input class="lx-evaluation__input text-xlarge me-3"
                                   type="radio"
                                   name="evaluation"
                                   id="evalNon"
                                   value="non"
                                   v-model="evaluationResponse">
                            <label class="lx-evaluation__label" for="evalNon">Non</label>
                        </div>
                    </div>
                    <!-- Only show textarea if "oui" or "non" is selected -->
                    <textarea v-if="evaluationResponse"
                              class="lx-evaluation__comment"
                              rows="4"
                              placeholder="Écrivez votre commentaire ici..."
                              v-model="evaluationComment">
                    </textarea>
                    <!-- Envoyer Button -->
                    <div v-if="evaluationResponse" class="d-flex justify-content-end mt-3">
                        <button class="btn btn-primary lexi-btn-primary" @click="sendEvaluationFeedback">Envoyer</button>
                    </div>
                    <!-- Tooltip for Copy Notification -->
                    <div v-if="showCopyTooltip" class="copy-tooltip">
                        Terme copié dans le presse-papiers
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppHeader from './AppHeader.vue';
    import { useDataService } from '../dataService.ts';
    import { getDatabase, ref, push } from "firebase/database";
    import app from "../firebaseConfig"; // Import the Firebase configuration file

    export default {
        name: 'DynamicPage',
        components: {
            AppHeader,
        },
        data() {
            return {
                term: '',
                resultDetails: {}, // Holds the details of the term
                isLoading: true, // Track loading state
                showToast: false, // Track toast visibility
                toastMessage: '', // Message to display in the toast
                showCopyTooltip: false, // Track tooltip visibility
                evaluationResponse: null, // Radio button value ("oui" or "non")
                evaluationComment: '', // Comment text box value
            };
        },
        computed: {
            highlightedContext() {
                // Bold the term in the context
                const termFR = this.resultDetails.VEDETTE_FRANÇAISE || '';
                const context = this.resultDetails.CONTEXTE || '';
                if (termFR && context.includes(termFR)) {
                    const regex = new RegExp(`(${termFR})`, 'gi');
                    return context.replace(regex, '<b>$1</b>');
                }
                return context;
            },
            linkedSource() {
                // Turn URLs into clickable links
                const source = this.resultDetails.SOURCE_CONTEXTE_FRANÇAISE || '';
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                return source.replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
            },
        },
        mounted() {
            this.term = this.$route.query.terme;
            console.log(`Fetching term: ${this.term}`);
            this.getTermInformation(this.term);
        },
        watch: {
            resultDetails(newVal) {
                console.log('resultDetails updated:', newVal);
                if (Object.keys(newVal).length > 0) {
                    this.isLoading = false;
                }
            },
        },
        methods: {
            async sendEvaluationFeedback() {
                if (!this.evaluationResponse) {
                    alert("Veuillez sélectionner une option (oui ou non).");
                    return;
                }

                const feedbackData = {
                    termforfeedback: this.term,
                    response: this.evaluationResponse, // "oui" or "non"
                    comment: this.evaluationComment, // User's comment
                    timestamp: new Date().toISOString(), // Timestamp
                };

                try {
                    const db = getDatabase(app); // Initialize the database
                    const feedbackRef = ref(db, "termFeedback"); // Reference the "evaluationFeedback" node
                    await push(feedbackRef, feedbackData); // Push the feedback data
                    alert("Merci pour votre retour !");
                    // Clear the form
                    this.evaluationResponse = null;
                    this.evaluationComment = "";
                } catch (error) {
                    console.error("Error saving feedback:", error);
                    alert("Erreur de connexion. Réessayez plus tard.");
                }
            },
            async getTermInformation(term) {
                try {
                    const dataService = useDataService();
                    const response = await dataService.getData2(term);
                    console.log('Fetched term details:', response);
                    this.resultDetails = { ...response };
                } catch (error) {
                    console.error(`Error fetching details for term "${term}":`, error);
                    this.isLoading = false;
                }
            },

            async speak(text) {
                try {
                    console.log('Term:', text);
                    const dataService = useDataService();
                    const audioData = await dataService.getSynthesizedSpeech(text);
                    console.log('Audio data received:', audioData);
                    const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
                    const audioUrl = URL.createObjectURL(audioBlob);
                    const audio = new Audio(audioUrl);
                    await audio.play().catch(error => {
                        console.error('Audio play failed:', error);
                    });
                    console.log('Audio is playing...');
                } catch (error) {
                    console.error("Error fetching audio:", error);
                }
            },

            async copyText(text) {
                if (text) {
                    try {
                        await navigator.clipboard.writeText(text);
                        this.showCopyTooltip = true;
                        setTimeout(() => {
                            this.showCopyTooltip = false;
                        }, 2000);
                    } catch (err) {
                        console.error('Failed to copy:', err);
                    }
                } else {
                    console.warn('No text to copy.');
                }
            },
            navigateToDomain(domain) {
                if (domain) {
                    const route = `/${domain
                        .normalize('NFD') // Normalize to separate accents from letters
                        .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
                        .toLowerCase()
                        .replace(/ /g, '-')}`; // Replace spaces with hyphens
                    console.log(`Navigating to: ${route}`);
                    this.$router.push(route);
                } else {
                    console.warn('No valid domain provided');
                }
            },
            getDomainImage(domain) {
                const domainMapping = {
                    'biologie': require('./../assets/Biology.svg'),
                    'génie aérospatial': require('./../assets/Aerospace.svg'),
                    'génie environnemental': require('./../assets/Environmental.svg'),
                    'génie des systèmes': require('./../assets/Systems.svg'),
                    'gestion': require('./../assets/Management.svg'),
                    'informatique': require('./../assets/Computer.svg'),
                    'sciences cognitives': require('./../assets/Cognitive.svg')
                };
                return domainMapping[domain.toLowerCase()] || require('./../assets/linkIcon.svg');
            },

            getDomainSubtitle(domain) {
                const subtitleMapping = {
                    'biologie': 'biology',
                    'génie aérospatial': 'aerospace engineering',
                    'génie environnemental': 'environmental engineering',
                    'génie des systèmes': 'systems engineering',
                    'gestion': 'management',
                    'informatique': 'computer science',
                    'sciences cognitives': 'neuroscience'
                };
                return subtitleMapping[domain.toLowerCase()] || domain;
            }
        },
    };
</script>

<style scoped>
    .text-burgundy {
        color: #912338;
    }

    .lx-details__button-icon {
        width: 20px !important;
        height: 20px !important;
    }

    .lx-details__button {
        width: auto !important;
        height: 36px !important;
        gap: .5rem;
        border-radius: 100px !important;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: white;
        border: 1px solid #c8c8c880;
        transition: background-color 0.15s ease;
        padding: 0.5rem 1rem !important;
        font-family: 'Arial', sans-serif;
        color: #262626;
        letter-spacing: -0.02em;
    }

    .lx-details__button:hover {
        background-color: #c8c8c880;
    }

    .lx-evaluation__comment {
        width: 100%;
    }

    .lx-evaluation {
        background-color: white;

        @media (max-width: 768px) {
            width: calc(100% - 24px) !important;
        }
    }

    .domain-value.clickable {
        color: #912338; /* Link color */
        text-decoration: underline;
        cursor: pointer;
        font-weight: 700;
    }

    .domain-value.clickable:hover {
        text-decoration: none;
    }



    /* You can remove most of the custom styles as they're replaced by Bootstrap classes */
    /* Keep only specific custom styles that aren't covered by Bootstrap */
    .card {
        cursor: pointer;
        transition: all 0.2s;
    }

    .card:hover {
        transform: translateY(-2px);
    }

    .card-body {
        background: white;
        border-radius: 4px;
        padding: 16px;
        transition: background-color 0.2s;
    }

    .card:hover .card-body {
        background-color: #F0F0F080;
    }

    .lx-details__english-term, .lx-details__french-term {
        border-left: 2px solid #912338;
        padding-left: 1.5rem;
    }

    .lx-language-label {
        color: #404040;
    }

    span.badge {
        vertical-align: 0.5em !important;
    }

    .copy-tooltip {
        position: fixed;
        bottom: 70px; /* Position above the copy icon */
        right: 20px;
        padding: 8px 16px;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        font-family: Arial, sans-serif;
        font-size: 14px;
        z-index: 1000;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        animation: fadeInOut 2s ease-in-out;
    }

    @keyframes fadeInOut {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        10%, 90% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(20px);
        }
    }
</style>
