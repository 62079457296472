<template>
  <div class="custom-container">
    <AppHeader />

    <main class="lx-search container-fluid">
      <div class="row justify-content-center width940 mx-auto">
        <div class="col-12">
          <div class="lx-search-header">
            <div class="lx-search-header__title">
                <h1 class="lx-h2" lang="fr"><span style="font-family: 'Gill Sans MT Pro Book'">Résultat pour </span><span style="font-family: 'Gill Sans MT Pro Bold Condensed'">{{ term }}</span></h1>
            </div>
            <div class="lx-search-header__results-bar">
              <p class="mb-0">{{ results.length }} résultats</p>
              <div>
                <button @click="toggleView" class="btn lx-btn lx-btn-secondary">
                  <svg v-if="!isCardView" class="me-2" style="width: 20px; height: 20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M12 5.83L15.17 9l1.41-1.41L12 3L7.41 7.59L8.83 9L12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15L12 18.17z"/>
                  </svg>
                  <svg v-else class="me-2" style="width: 20px; height: 20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M7.41 18.59L8.83 20L12 16.83L15.17 20L16.58 18.59L12 14L7.41 18.59M16.59 5.41L15.17 4L12 7.17L8.83 4L7.41 5.41L12 10L16.59 5.41Z"/>
                  </svg>
                  <span class="text-xlarge">
                    {{ isCardView ? 'Vue sommaire' : 'Vue détaillée' }}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <ul class="lx-search-results row list-unstyled">
            <li v-for="result in results" :key="result.details.VEDETTE_FRANÇAISE">
                <a class="lx-search-results__item" :class="{ 'lx-search-results__item--list lx-search-results__item--list-small': !isCardView }" href="#" @click.prevent="navigateToDetails(result)" tabindex="0">
                  <div class="lx-search-results__header">
                    <div class="lx-search-results__header-row">
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="d-flex align-items-center">
                          <span class="lx-h3">{{ result.details.VEDETTE_FRANÇAISE }}</span>
                          <span class="badge lx-bg-light text-dark mx-2">FR</span>
                        </div>
                        <div class="d-flex align-items-center gap-1">
                          <span>•</span>
                          <span class="lx-h3-book ms-2">{{ result.details.VEDETTE_ANGLAISE }}</span>
                          <span class="badge lx-bg-light text-dark ms-2">EN</span>
                        </div>
                      </div>
                    </div>
                    <div class="lx-search-results__subtitle">
                      <span class="text-xlarge lx-text-light-100">{{ result.details.DOMAINE }}</span>
                      <span class="mx-2">|</span>
                      <span class="text-xlarge lx-text-light-100">{{ result.details.SOUS_DOMAINE }}</span>
                    </div>
                  </div>
                  <div v-if="isCardView" class="mt-3 width768">
                    <span class="text-xlarge lx-text-light-200">{{ result.details.DEFINITION }}</span>
                  </div>
                </a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import { useDataService } from '../dataService.ts';

export default {
  name: 'SearchPage',
  components: {
    AppHeader,
  },
  data() {
    return {
      term: '',
      results: [], // Store search results
      isCardView: true, // Track view state
      isFlipped: false, // Track language state
    };
  },
  mounted() {
    this.term = this.$route.query.terme;
    this.fetchSearchResults(this.term);
  },
  watch: {
    '$route.query.terme': function(newTerm) {
      this.term = newTerm;
      this.fetchSearchResults(newTerm);
    }
  },
  methods: {
    async fetchSearchResults(term) {
      const dataService = useDataService();
      try {
        const response = await dataService.getRelatedTerms(term);
        const filteredResults = response
          .filter((result) => result.toLowerCase().startsWith(term.toLowerCase()))
          .slice(0, 10);

        const promises = filteredResults.map(async (term) => {
          const details = await this.getTermInformation(term);
          return { details };
        });

        this.results = await Promise.all(promises);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    },

    async getTermInformation(term) {
      const dataService = useDataService();
      try {
        const response = await dataService.getData2(term);
        return response;
      } catch (error) {
        console.error(`Error fetching details for term "${term}":`, error);
        return {};
      }
    },

    navigateToDetails(result) {
      const englishTerm = result.details.VEDETTE_ANGLAISE;
      this.$router.push({
        path: '/details',
        query: { terme: englishTerm },
      });
    },

    toggleView() {
      this.isCardView = !this.isCardView;
    },

    toggleLanguage() {
      this.isFlipped = !this.isFlipped;
    },
  }
};
</script>

<style scoped>
/* Main container */
.main-container {
  width: 940px;
  padding: 60px 16px;
}

/* Search section */
.lx-search {
  margin-bottom: 7.5rem;
}

/* Search header */
.lx-search-header__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.lx-search-header__results-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #C8C8C8;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 10px;
}

.lexi-result-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.view-toggle-button {
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.view-toggle-button:hover {
  background-color: #f0f0f0;
}

.result-text {
  font-family: 'Gill Sans MT Pro', sans-serif;
  font-size: 2rem;
}

.result-count {
  font-size: 1rem;
}

/* Search results */
.lx-search-results__item {
  text-decoration: none !important;
  color: black !important;
  padding: 1.25rem 0;
  border-bottom: 1px solid #C8C8C8;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;

  @media (min-width: 992px) {
    padding: 1.25rem;
  }
}

.lx-search-results__item a, 
.lx-search-results__item a:hover {
  text-decoration: none !important;
  color: black !important;
}

.lx-search-results__item:hover {
  background-color: #F0F0F0 !important;
}

.lx-search-results__header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  transition: all 0.2s ease;
}

.lx-search-results__item--list-small .lx-search-results__header {
  gap: 0.25rem;
}

.lx-search-results__header-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  transition: all 0.2s ease;
}

/* List view modifiers */
.lx-search-results__item--list-small {
  padding: .75rem 1rem;
  transition: all 0.2s ease;
}

.lx-search-results__item--list-small .lx-search-results__header .lx-h3,
.lx-search-results__item--list-small .lx-h3-book {
  font-size: 1.25rem !important;
  transition: all 0.2s ease;
}

.lx-search-results__item--list-small .lx-text-light-100 {
  font-size: 1rem !important;
  transition: all 0.2s ease;
}

.lx-search-results__item--list-small .lx-search-results__header-row {
  gap: .5rem;
  transition: all 0.2s ease;
}

/* Term section */
.term-section {
  width: 940px;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 16px;
  border-top: 1px solid #C8C8C8;
  transition: background-color 0.2s ease;
}

.term-section:hover {
  background: rgba(240, 240, 240, 0.6);
}

/* Details box */
.details-box {
  padding: 10px;
}

.details-box.list-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
} 

.row-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.left-content, 
.right-content {
  width: 100%;
  text-align: left;
  margin-top: 5px;
}

.right-content {
  margin-top: 1px;
}

/* Typography */
.frenchterm {
  font-family: "Gill Sans MT Pro Bold Condensed";
}

.row-2 {
  font-family: 'Gill Sans MT Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.definition {
  font-family: 'Arial';
}

/* Language box */
.language-box {
  width: 34px;
  height: 23px;
  padding: 4px 8px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #f0f0f0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

/* Utilities */
.custom-container {
  overflow-x: hidden;
}

/* Responsive */
@media (max-width: 992px) {
  .main-container {
    width: 768px;
  }
  .term-section {
    width: 768px;
  }
}

@media (max-width: 768px) {
  .main-container {
    width: 100%;
  }
  .term-section {
    width: 100%;
  }
}
</style>
