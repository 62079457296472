const { createRouter, createWebHashHistory } = require('vue-router');

const HomePage = require('../components/HomePage.vue').default;
const DynamicPage = require('../components/DynamicPage.vue').default;
const IndexPage = require('../components/IndexPage.vue').default;
const AboutPage = require('../components/About.vue').default;
const Aerospace = require('../components/Aerospace.vue').default;
const Biology = require('../components/Biology.vue').default;
const Envoirmental = require('../components/Envoirmental.vue').default;
const Informatics = require('../components/Informatics.vue').default;
const Management = require('../components/Management.vue').default;
const Systems = require('../components/Systems.vue').default;
const Cognitive = require('../components/Cognitive.vue').default;
const SearchPage = require('../components/SearchPage.vue').default;

const routes = [
  { path: '/', component: HomePage },
  { path: '/details', component: DynamicPage },
  { path: '/index', component: IndexPage },
  { path: '/about', component: AboutPage },
  { path: '/genie-aerospatial', component: Aerospace },
  { path: '/biologie', component: Biology },
  { path: '/genie-environnemental', component: Envoirmental },
  { path: '/informatique', component: Informatics },
  { path: '/gestion', component: Management },
  { path: '/genie-des-systemes', component: Systems },
  { path: '/sciences-cognitives', component: Cognitive },
  { path: '/recherche', component: SearchPage }, // Add the new route for SearchPage
];

console.log("Creating router with routes:", routes);

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    
    console.log("scrolling to top...");
  
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 0); // Delay scrolling until DOM updates are complete
    });
  }
  
});

console.log("Router created:", router);

module.exports = router;
