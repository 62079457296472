const axios = require('axios');

//const addressLocal = "http://localhost:8089";
//const addressServer = "https://rselpllexweb01.concordia.ca/lexicApi";
const addressServer = "https://lexi.concordia.ca/lexicApi";
export function useDataService() {
  // Create an Axios instance
  const axiosInstance = axios.create();

  // Return a new cancel token source
  const getCancelTokenSource = () => {
    return axios.CancelToken.source();
  };

  const getData = async () => {
    try {
      const response = await axiosInstance.get(`${addressServer}/terms`);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const getData2 = async (term) => {
    try {
      const response = await axiosInstance.get(`${addressServer}/terms/${term}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching data for term ${term}:`, error);
      throw error;
    }
  };

  const getRelatedTerms = async (term, cancelToken) => {
    try {
      const response = await axiosInstance.get(`${addressServer}/relatedterms/${term}`, {
        cancelToken,
      });
      return response.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(`Request canceled: ${error.message}`);
      } else {
        console.error(`Error fetching related terms for ${term}:`, error);
        throw error;
      }
    }
  };

  const getRowsForDomaine = async (dm) => {
    try {
      const response = await axiosInstance.get(`${addressServer}/domaine/${dm}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching related terms for ${dm}:`, error);
      throw error;
    }
  };

  const getRowsForTermsWithLanguage = async () => {
    try {
      const response = await axiosInstance.get(`${addressServer}/termswithLanguage`);
      return response.data;
    } catch (error) {
      console.error("Error fetching terms with languages:", error);
      throw error;
    }
  };

  const getCountByDomain = async (domain) => {
    try {
      const response = await axiosInstance.get(`${addressServer}/countByDomain/${domain}`);
      console.log(response.data.count);
      return response.data.count;
    } catch (error) {
      console.error(`Error fetching count for domain ${domain}:`, error);
      throw error;
    }
  };

  const getSynthesizedSpeech = async (text) => {
    try {
      console.log('before :', text);
      const response = await axiosInstance.get(`${addressServer}/synthesize/${encodeURIComponent(text)}`, {
        responseType: 'arraybuffer', // This ensures you get the raw audio data
      });
      return response.data; // This should be an ArrayBuffer containing MP3 audio data
    } catch (error) {
      console.error(`Error synthesizing speech for text "${text}":`, error);
      throw error;
    }
  };

  return {
    getCancelTokenSource,
    getData,
    getData2,
    getRelatedTerms,
    getRowsForDomaine,
    getRowsForTermsWithLanguage,
    getCountByDomain,
    getSynthesizedSpeech,
  };
}
