<template>
  <!-- Header Section -->
  <!-- <header class="row justify-content-center">
  </header> -->

  <!-- Off-canvas Navigation -->
  <!-- <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li class="nav-item">
          <router-link to="/index" class="nav-link nav-text" lang="fr">Index général</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/about" class="nav-link nav-text" lang="fr">Notre équipe</router-link>
        </li>
      </ul>
    </div>
  </div> -->

  <!-- Search Bar Section -->
  <div class="search-div row align-items-center justify-content-center position-relative lx-bg-light" ref="searchWrapper">
    <div class="head d-flex justify-content-center align-items-center">
        <router-link to="/">
          <img src="@/assets/Logo.svg" alt="LEXICONCORDIA" class="img-fluid logo-lexiconcordia py-5" />
        </router-link>
      </div>
      <div class="container-fluid">
        <div class="row justify-content-center text-center">
          <div class="col-12 width940">
            <p class="description-text mb-5 mb-lg-3" lang="fr">
              Maîtrisez le français technique et scientifique.
            </p>
          </div>
        </div>
      </div>  
    <div class="width768 search position-absolute" style="width: 768px; max-width: calc(100vw - 24px); bottom: -40px;">
      <!-- Search Input Field -->
      <div class="container-fluid position-relative">
        <div class="form-group">
          <form @submit.prevent="onSubmit">
            <div class="position-relative">
              <input
                type="text"
                class="form-control search-input py-3 rounded-2 shadow"
                placeholder="Rechercher un terme..."
                v-model="textInput"
                @input="onSearchInputChange"
                @keydown.esc="clearSearch"
                required
              />
              <img src="./../assets/Vector.svg" alt="Search Icon" class="search-icon" @click="onSubmit" />
              <img v-if="textInput != ''" src="./../assets/Close.svg" alt="Close Icon" class="close-icon" @click="clearSearch" />
            </div>
          </form>
          <div v-if="textInput.length >= 3" class="suggestion-dropdown shadow position-absolute">
              <ul class="dropdn">
                  <li v-for="(item, index) in relatedWords.slice(0, 10)"
                      :key="item.word"
                      :class="{ highlighted: index === highlightedIndex }"
                      ref="dropdownItems"
                      @click="onWordClick(item.word)">
                      <div class="d-flex align-items-center gap-2 w-100">
                          <div class="d-flex align-items-center">
                              <img src="./../assets/Vector.svg" alt="Search Icon" class="search-icon-list" />
                              <span v-html="highlightMatchedText(item.word)"></span>
                          </div>
                          <span class="badge lx-bg-light text-dark" style="font-size: .75rem;">{{ item.isFrench ? 'FR' : 'EN' }}</span>
                      </div>
                  </li>

                  <!-- Conditional message based on isSearching -->
                  <li v-if="relatedWords.length === 0" class="no-results">
                      <span v-if="isSearching">À la recherche de termes...</span>
                      <span v-else>Pas de résultat</span>
                  </li>
              </ul>
          </div>
        </div>
      </div>
          <!-- Dropdown for Suggested Words -->
      
    </div>
  </div>
</template>

<script>
const { ref, onMounted, onBeforeUnmount } = require('vue');
const { useRouter } = require('vue-router');
const { useDataService } = require('../dataService.ts');
const axios = require('axios');

export default {
  name: 'AppHeader',
  setup() {
    const title = 'lexic';
    const data = ref(null);
    const data2 = ref(null);
    const data1 = ref(false);
    const ifdata2 = ref(false);
    const textInput = ref('');
    const relatedWords = ref([]);
    const searchWrapper = ref(null);
    const highlightedIndex = ref(-1); // Track the highlighted index
    const dropdownItems = ref([]); // Track dropdown items
    const isSearching = ref(true); // Flag to track if a search is in progress

    let cancelTokenSource = null; // Store the cancel token

    const dataService = useDataService();
    const router = useRouter();

    const onIndex = () => {
      console.log("Navigating to Index Page...");
      router.push('/index');
    };

    const onAbout = () => {
      console.log("Navigating to About Page...");
      router.push('/about');
    };

      const onSearchInputChange = async () => {
          if (textInput.value.trim().length >= 3) { // Ensure at least 3 characters
              isSearching.value = true;

              // Cancel the previous request if it exists
              if (cancelTokenSource) {
                  cancelTokenSource.cancel("New search triggered");
              }

              cancelTokenSource = axios.CancelToken.source();

              try {
                  // Fetch related terms with cancellation support
                  const relatedTerms = await dataService.getRelatedTerms(
                      textInput.value,
                      cancelTokenSource.token
                  );

                  const normalizedInput = textInput.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                  // Filter and alphabetize before truncating to 10
                  const filteredWords = relatedTerms
                      .filter((word) =>
                          word.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().startsWith(normalizedInput.toLowerCase())
                      )
                      .sort((a, b) => a.localeCompare(b, 'fr')); // Sort alphabetically

                  // Truncate to 10 items after sorting
                  const truncatedWords = filteredWords.slice(0, 10);

                  // Check the first ten terms for language
                  const termDetailsPromises = truncatedWords.map(async (word, index) => {
                      if (index < 10) {
                          try {
                              const details = await dataService.getData2(word, cancelTokenSource.token);
                              return {
                                  word,
                                  isFrench: word === details.VEDETTE_FRANÇAISE,
                              };
                          } catch (err) {
                              if (!axios.isCancel(err)) {
                                  console.error(`Error fetching term details for ${word}:`, err.message);
                              }
                              return { word, isFrench: false };
                          }
                      }
                      return { word, isFrench: false }; // Default to EN for the rest
                  });

                  relatedWords.value = await Promise.all(termDetailsPromises);
                  highlightedIndex.value = -1;
              } catch (error) {
                  if (axios.isCancel(error)) {
                      console.log("Previous request canceled", error.message);
                  } else {
                      console.error('Error fetching related terms:', error.message);
                  }
              } finally {
                  isSearching.value = false;
              }
          } else {
              relatedWords.value = [];
          }
      };



    const onWordClick = (word) => {
      textInput.value = word;
      onSubmit();  // This will now trigger the routing to SearchPage.vue
    };


    const onSubmit = () => {
      if (textInput.value) {
        router.push({
          path: '/recherche',
          query: { terme: textInput.value },
        });
        clearSearch();
      }
    };

    const highlightMatchedText = (word) => {
      const searchText = textInput.value.trim().toLowerCase();
      const wordLowerCase = word.toLowerCase();

      if (wordLowerCase.startsWith(searchText)) {
        const matchedText = word.substring(0, searchText.length);
        const remainingText = word.substring(searchText.length);

        return `
          <span style="font-weight: 700;">${matchedText}</span><span style="font-weight: 500;">${remainingText}</span>
        `;
      }
      return `<span style="font-weight: 500;">${word}</span>`;
    };

    const goToDetailsPage = () => {
      router.push({
        path: '/details',
        query: { data: JSON.stringify(data2.value) },
      });
    };

    const clearSearch = () => {
      textInput.value = ''; 
      relatedWords.value = [];
      highlightedIndex.value = -1; // Reset index when clearing the search
    };

    const goToSearchPage = () => {
      router.push('/recherche');
    };

    const handleKeyDown = (event) => {
      const maxIndex = Math.min(relatedWords.value.length, 10) - 1;

      switch (event.key) {
        case 'ArrowDown':
          if (highlightedIndex.value < maxIndex) {
            highlightedIndex.value += 1;
          }
          break;

        case 'ArrowUp':
          if (highlightedIndex.value > 0) {
            highlightedIndex.value -= 1;
          }
          break;

        case 'Enter':
              if (highlightedIndex.value >= 0 && highlightedIndex.value <= maxIndex) {
                  onWordClick(relatedWords.value[highlightedIndex.value].word);
              }
          break;

        default:
          break;
      }

      // Auto-scroll to ensure the highlighted item is visible
      const dropdownItemsList = searchWrapper.value.querySelectorAll('li');
      const dropdownItem = dropdownItemsList[highlightedIndex.value];

      if (dropdownItem) {
        dropdownItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    };

    // Close dropdown when clicked outside the specific search input area
    const handleClickOutside = (event) => {
      const searchContainer = document.querySelector('.form-group');
      if (searchContainer && !searchContainer.contains(event.target)) {
        clearSearch();
      }
    };

    // Add and remove event listeners
    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    });

    return {
      title,
      data,
      data2,
      data1,
      ifdata2,
      onIndex,
      onAbout,
      textInput,
      relatedWords,
      onSearchInputChange,
      onWordClick,
      onSubmit,
      goToDetailsPage,
      clearSearch,
      searchWrapper,
      goToSearchPage,
      highlightedIndex,
      dropdownItems,
      highlightMatchedText,
      isSearching
    };
  },
};

</script>


<style scoped>
header {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.head {
  width: 940px;
}

.nav-text {
  color: #912338;
  font-size: 0.95rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-family: 'Gill Sans MT Pro';
  text-align: left;
}

.nav-text:hover {
  text-decoration: underline;
}

.nav-icon{
  display: none;
}

.logo-lexiconcordia {
  width: 30vw;
  min-width: 300px;
  max-width: 500px !important;
}

ul.dropdn {
  overflow-y: hidden !important;
  padding-left: 0px !important;
}

.description-text {
  font-size: 24px;
  letter-spacing: -0.02em;
  text-wrap: balance;
  font-family: 'Gill Sans MT Pro Book';

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.search-div {
  /* background-color: #f0f0f0; */
  padding: 80px 0;
  margin-bottom: 7.5rem;
}

.search {
  width: 768px;
  padding: 1rem 0;
  position: relative;
}

.form-control{
  border: none;
}

.search-input {
  padding: 1rem;
  border-radius: .25rem;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.highlighted {
  background-color: #f0f0f0;
  cursor: pointer;
}

.search-icon-list {
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
}

.offcanvas.offcanvas-end{
  width: 11rem;
}

.close-icon {
  position: absolute;
  top: 50%;
  right: 3.25rem;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}


.suggestion-dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
  max-height: 144px; /* Adjust this for exactly 5 list elements if needed */
  overflow-y: hidden;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 0px 0px 4px 4px;
  margin-top: -5px;
  border-bottom: 1px solid #912338;
  border-left: 1px solid #912338;
  border-right: 1px solid #912338;

}


/* Ensure consistent height for exactly five items */
.suggestion-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestion-dropdown li {
  padding: 1.5rem !important;
  cursor: pointer;
  height: 48px !important;
  display: flex;
  align-items: center;
  color: var(--grey-700, #000);
  font-family: "Gill Sans MT Pro Book";
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.suggestion-dropdown li:hover {
  background-color: #f0f0f0;
}

/* Adjust height if there are at least 5 items */
.suggestion-dropdown ul:has(li:nth-child(5)) {
  max-height: calc(40px * 5); /* Limit to 5 items */
  overflow-y: auto;
}

/* Ensure no color change on hover and apply consistent gray styling */
.suggestion-dropdown .no-results {
    padding: 12px;
    color: gray !important; /* Apply gray color consistently */
    font-family: "Gill Sans MT Pro Book";
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    background-color: white !important; /* Ensure background remains white */
    cursor: default;
}

/* Prevent hover effect on .no-results */
.suggestion-dropdown .no-results:hover {
    background-color: white !important; /* Stay white on hover */
    color: gray !important; /* Stay gray on hover */
    cursor: default;
}
  
.search-icon-list {
  filter: grayscale(100%);
  opacity: 0.5; /* Optional: adjust opacity to fine-tune the gray tone */
  width: 16px;
  margin-right: 10px;
}

/* Responsive styling */
@media (max-width: 992px) {
  .head {
    width: 736px;
  }
  .search-div {
    padding: 40px 16px;
  }
  .search {
    width: 736px;
  }
}

@media (max-width: 768px) {
  header {
    padding: 8px 16px;
  }
  .nav {
    display: none;
  }
  .search-div {
    padding: 20px 24px;
  }
}
</style>
