<template>
  <div class="custom-container" id="top">
    <AppHeader />

    <main class="container width1200 d-flex flex-column">
      <!-- Left Content with Terms -->
      <!-- Header Row with Buttons -->
      <h1 class="lx-h1" lang="fr">Génie aérospatial</h1>
            <h2 class="lx-h2" lang="fr">Exercices</h2>
            <div class="row mb-5 gy-4">
                <div class="col-lg-4 col-sm-6 col-12">
                    <a href="https://lexi-concordia-exercices.github.io/masculin-ou-feminin-aerospatial/" class="card text-decoration-none text-dark p-4">
                        <div class="card-body p-0 d-flex justify-content-between ">
                            <div class="d-flex flex-column w-75">
                                <h3 class="lx-h3 card-title">Masculin ou féminin?</h3>
                                <p class="card-text">Quel est le genre grammatical des termes?</p>
                            </div>
                            <div style="font-size: 1.5rem; line-height: 1;">
                                <svg class="ci" role="presentation" aria-hidden="true" color="#912338">
                                    <use href="/img/sprites.svg#ci-external-link"></use>
                                </svg>
                            </div>
                        </div>
                    </a>
                </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <a href="https://lexi-concordia-exercices.github.io/Exercice-dassociation-aerospatial/" class="card text-decoration-none text-dark p-4">
            <div class="card-body p-0 d-flex justify-content-between ">
              <div class="d-flex flex-column w-75">
                <h3 class="lx-h3 card-title">Exercice d'association</h3>
                <p class="card-text">Entraînez-vous à associer les termes à leur concept.</p>
              </div>
              <div style="font-size: 1.5rem; line-height: 1;">
                <svg class="ci" role="presentation" aria-hidden="true" color="#912338">
                  <use href="/img/sprites.svg#ci-external-link"></use>
                </svg>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <a href="https://lexi-concordia-exercices.github.io/oreilles-techniques-aerospatial/" class="card text-decoration-none text-dark p-4">
            <div class="card-body p-0 d-flex justify-content-between ">
              <div class="d-flex flex-column w-75">
                <h3 class="lx-h3 card-title">Oreilles techniques</h3>
                <p class="card-text">Réinterprétez des informations entendues à l'oral.</p>
              </div>
              <div style="font-size: 1.5rem; line-height: 1;">
                <svg class="ci" role="presentation" aria-hidden="true" color="#912338">
                  <use href="/img/sprites.svg#ci-external-link"></use>
                </svg>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-4 col-sm-6 col-12">
          <a href="https://lexi-concordia-exercices.github.io/jeu-de-memoire-aerospatial/" class="card text-decoration-none text-dark p-4">
            <div class="card-body p-0 d-flex justify-content-between ">
              <div class="d-flex flex-column w-75">
                <h3 class="lx-h3 card-title">Jeu de mémoire</h3>
                <p class="card-text">Quels sont les termes anglais et français correspondants?</p>
              </div>
              <div style="font-size: 1.5rem; line-height: 1;">
                <svg class="ci" role="presentation" aria-hidden="true" color="#912338">
                  <use href="/img/sprites.svg#ci-external-link"></use>
                </svg>
              </div>
            </div>
          </a>
        </div>
      </div>
      <h2 class="lx-h2" lang="fr">Index</h2>
      <div class="lx-search-header__results-bar">
        <p class="mb-0">{{ results.length }} résultats</p>
        <div class="button-group">
          <button @click="toggleView" class="btn lx-btn lx-btn-secondary">
            <svg v-if="!isCardView" class="me-2" style="width: 20px; height: 20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor" d="M12 5.83L15.17 9l1.41-1.41L12 3L7.41 7.59L8.83 9L12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15L12 18.17z"/>
            </svg>
            <svg v-else class="me-2" style="width: 20px; height: 20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path fill="currentColor" d="M7.41 18.59L8.83 20L12 16.83L15.17 20L16.58 18.59L12 14L7.41 18.59M16.59 5.41L15.17 4L12 7.17L8.83 4L7.41 5.41L12 10L16.59 5.41Z"/>
            </svg>
            <span class="text-xlarge">
              {{ isCardView ? 'Vue sommaire' : 'Vue détaillée' }}
            </span>
          </button>
        </div>
      </div>
      <div id="main-row" class="row flex-column flex-lg-row-reverse">
        <!-- Right Column - Alphabet Menu -->
        <div class="col-12 col-lg-3 d-flex justify-content-end">
          <nav class="alphabet-menu border mt-5" aria-label="alphabetic-navigation">
            <p class="menu-label">Aller à:</p>
            <button
              v-for="letter in alphabet"
              :key="letter"
              @click="scrollToSection(letter)"
              :class="{'inactive': !groupedResults[letter]}"
            >
              {{ letter }}
            </button>
          </nav>
        </div>
        <!-- Left Column - Terms List -->
        <div class="col-12 col-lg-9">
          <ol class="terms-container list-unstyled">  
            <!-- Grouped Results by Alphabet -->
            <li v-for="(group, letter) in groupedResults" :key="letter" :id="letter" class="letter-section">
              <h2 class="lx-h1 pb-3 mt-5 ms-1">{{ letter }}</h2>
              <ul class="lx-search-results row list-unstyled">
                <li v-for="result in group" :key="result.VEDETTE_FRANÇAISE">
                  <a class="lx-search-results__item" 
                     :class="{ 'lx-search-results__item--list lx-search-results__item--list-small': !isCardView }" 
                     href="#" 
                     @click.prevent="navigateToDetails(result)" 
                     tabindex="0"
                  >
                    <div class="lx-search-results__header">
                      <div class="lx-search-results__header-row">
                        <div class="d-flex flex-wrap align-items-center">
                          <div class="d-flex align-items-center">
                            <span class="lx-h3">{{ result.VEDETTE_FRANÇAISE }}</span>
                            <span class="badge lx-bg-light text-dark ms-2">{{ isFlipped ? 'EN' : 'FR' }}</span>
                          </div>
                          <div class="d-flex align-items-center ms-2">
                            <span>•</span>
                            <span class="lx-h3-book ms-2">{{ result.VEDETTE_ANGLAISE }}</span>
                            <span class="badge lx-bg-light text-dark ms-2">{{ isFlipped ? 'FR' : 'EN' }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="lx-search-results__subtitle">
                        <span class="text-xlarge lx-text-light-100">{{ result.DOMAINE }}</span>
                        <span class="mx-2">|</span>
                        <span class="text-xlarge lx-text-light-100">{{ result.SOUS_DOMAINE }}</span>
                      </div>
                    </div>
                    <div v-if="isCardView" class="mt-3 width768">
                      <span class="text-xlarge lx-text-light-200">{{ result.DEFINITION }}</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </main>

    <!-- Tooltip for Copy Notification -->
    <button 
      :style="{ opacity: showScrollButton ? 1 : 0, visibility: showScrollButton ? 'visible' : 'hidden' }" 
      @click="scrollToSection('top')" 
      class="copy-tooltip shadow"
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="currentColor">
        <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z"/>
      </svg>
    </button>

  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import { useDataService } from '../dataService.ts';

export default {
name: 'AerospacePage',
components: {
  AppHeader,
},
data() {
  return {
    showScrollButton: false,
    results: [], // Store fetched results
    isCardView: true, // Track view state
    isFlipped: false, // Track language state
    alphabet: ['#', ...Array.from(Array(26), (_, i) => String.fromCharCode(65 + i))], // #, A-Z
  };
},
computed: {
  groupedResults() {
    const grouped = {};
    this.results.forEach((result) => {
      const term = result.VEDETTE_FRANÇAISE;

      // Normalize the term to remove accents and diacritics
      const normalizedTerm = term.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const firstChar = /^[A-Za-z]/.test(normalizedTerm[0])
        ? normalizedTerm[0].toUpperCase()
        : '#';

      if (!grouped[firstChar]) {
        grouped[firstChar] = [];
      }
      grouped[firstChar].push(result);
    });

    return grouped;
  }
},
mounted() {
  this.fetchSearchResults();
  window.addEventListener('scroll', this.handleScroll);
  window.addEventListener('scroll', this.handleStickyMenu);
  window.addEventListener('resize', this.handleStickyMenu);
},
unmounted() {
  window.removeEventListener('scroll', this.handleScroll);
  window.removeEventListener('scroll', this.handleStickyMenu);
  window.removeEventListener('resize', this.handleStickyMenu);
},
methods: {
  async fetchSearchResults() {
    const dataService = useDataService();
    try {
      const response = await dataService.getRowsForDomaine('génie aérospatial');
      this.results = response;
    } catch (error) {
      console.error('Error fetching aerospace terms:', error);
    }
  },
  handleStickyMenu() {
    const row = document.getElementById('main-row');
    const menu = document.querySelector('.alphabet-menu');

    if (!row || !menu) return;

    // Check if the screen width is less than 992px
    if (window.innerWidth < 992) {
      menu.classList.remove('fixed-menu');
      return;
    }

    const rowTop = row.getBoundingClientRect().top;

    if (rowTop <= 0) {
      menu.classList.add('fixed-menu');
    } else {
      menu.classList.remove('fixed-menu');
    }
  },
  toggleView() {
    this.isCardView = !this.isCardView;
  },
  toggleLanguage() {
    this.isFlipped = !this.isFlipped;
  },
  navigateToDetails(result) {
    const englishTerm = result.VEDETTE_ANGLAISE;
    this.$router.push({
      path: '/details',
      query: { terme: englishTerm },
    });
  },
  scrollToSection(letter) {
    const section = document.getElementById(letter);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  },
  handleScroll() {
    this.showScrollButton = window.scrollY > 200;
  },
},
};
</script>

<style scoped>
.custom-container {
display: flex;
flex-direction: column;
width: 100%;
overflow-x: hidden;
}

.terms-container {
flex: 1;
}

.header-row {
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
margin-top: 10px;
}

.button-group {
display: flex;
gap: 10px;
}

.view-toggle-button {
background-color: white;
color: black;
border: none;
padding: 8px 16px;
cursor: pointer;
border-radius: 4px;
}

.view-toggle-button:hover {
background-color: #f0f0f0;
}

.alphabet-menu {
display: flex;
flex-wrap: wrap;
align-items: center !important;
gap: 6px;
width: 100%;
height: fit-content;
max-height: calc(100vh - 100px);
overflow-y: auto;
padding: 1.5rem;
border-radius: 4px;

@media (min-width: 992px) {
  max-width: 160px;
  padding: .5rem;
  justify-content: center;
}
}

.menu-label {
color: var(--Text-Body-text, #2C2C2C);
width: 100%;
font-family: "Arial";
font-size: var(--Productive-H3-Label, 20px);
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: left;
grid-column: span 3; /* Span across all columns */
margin-bottom: 0 !important;
}

.alphabet-menu button {
width: 40px;
height: 40px; /* Adjust size if needed */
font-size: 14px;
font-weight: bold;
color: black;
background-color: rgba(240, 240, 240, 1.0);
border: none;
border-radius: 2px !important;
cursor: pointer;
text-align: center;
}

.alphabet-menu button.inactive {
background-color: white;
color: gray;
cursor: default;
}

.alphabet-menu button:not(.inactive):hover {
background-color: #C8C8C8;
}

.letter-section {
margin-bottom: 30px;
}

.result-text {
font-family: 'Gill Sans MT Pro Bold Condensed', sans-serif;
font-size: 4rem;
}

.copy-tooltip {
position: fixed;
bottom: 70px;
right: 20px;
padding: .75rem .85rem ;
background-color: #333;
color: #fff;
border-radius: 4px !important;
font-family: Arial, sans-serif;
font-size: 14px;
z-index: 1000;
border: 0 !important;
cursor: pointer;
transition: opacity 0.3s ease, visibility 0.3s ease;
}

.lx-search-results__item {
text-decoration: none !important;
color: black !important;
padding: 1.25rem 0;
border-bottom: 1px solid #C8C8C8;
display: flex;
flex-direction: column;
transition: all 0.2s ease;

@media (min-width: 992px) {
  padding: 1.25rem;
}
}

.lx-search-results__item:hover {
background-color: #F0F0F0 !important;
}

.lx-search-results__header {
display: flex;
flex-direction: column;
gap: 0.5rem;
align-items: flex-start;
transition: all 0.2s ease;
}

.lx-search-results__item--list-small {
padding: 1rem .5rem;
transition: all 0.2s ease;
}

.lx-search-results__item--list-small .lx-search-results__header .lx-h3,
.lx-search-results__item--list-small .lx-h3-book {
font-size: 1.25rem !important;
transition: all 0.2s ease;
}

.lx-search-header__results-bar {
display: flex;
justify-content: space-between;
align-items: center;
padding-bottom: 0.5rem;
border-bottom: 1px solid #C8C8C8;
width: 100%;
}

.alphabet-menu.fixed-menu {
  position: fixed;
  top: 10px; /* Adjust as needed */
  right: 20px; /* Adjust based on your layout */
  z-index: 100; /* Ensure it stays above content */
  background-color: white;
  width: 160px; /* Maintain consistent width */
}
</style>
