import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCJxN28hh58iWQHnsapTo4sAqZWkBsfeG8",
    authDomain: "lexiconcordiafeedback.firebaseapp.com",
    projectId: "lexiconcordiafeedback",
    storageBucket: "lexiconcordiafeedback.firebasestorage.app",
    messagingSenderId: "101187855706",
    appId: "1:101187855706:web:593004f3fd90e36a88ff63",
    measurementId: "G-X0EQHLS2HZ"
  };  

const app = initializeApp(firebaseConfig);
export default app;
